import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

// :: Components
import Dropdown from '../Dropdown/Dropdown';
import Input from '../Input/Input';
import HeaderFilterDatasource from './HeaderFilterDatasource';
import CheckCircle from '../CheckCircle/CheckCircle';
import ElementFromPlugin from '../ElementFromPlugin/ElementFromPlugin';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import { GridRenderFilterEvent } from '../../lib/flotiq-plugins/plugin-events/GridRenderFilterEvent';

// :: Hooks
import useDebounceCallback from '../../hooks/useDebounceCallback';
import usePluginResults from '../../hooks/usePluginResults';

const HeaderFilter = ({
  id,
  filterInputType,
  handleFilters,
  value,
  currentFilters,
  options,
  testId,
  dropdownLeftPosition,
  validation,
  disabled,
  extraOptions,
  filterDatasourceFetch,
  contentType,
  multiple,
}) => {
  const { t } = useTranslation();
  let inputComponent;
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  const handleFilterDebounced = useDebounceCallback((id, e) => {
    setCurrentValue(e.target.value);
    handleFilters(id, e.target.value);
  }, 500);

  /**
   * @emits FlotiqPlugins."flotiq.grid.filter::render"
   */
  const results = usePluginResults(
    'flotiq.grid.filter::render',
    GridRenderFilterEvent,
    {
      inputType: filterInputType,
      accessor: id,
      updateFilters: handleFilters,
      allFilters: currentFilters,
      disabled,
      contentType,
    },
  );

  if (results && results.length > 0) {
    inputComponent = <ElementFromPlugin results={results} />;
  } else if (
    filterInputType === 'select' ||
    filterInputType === 'checkbox' ||
    filterInputType === 'radio'
  ) {
    let dropdownOptions = options;

    if (filterInputType === 'checkbox')
      dropdownOptions = [
        {
          searchString: t('Global.True'),
          label: (
            <div className="flex flex-row justify-center items-center">
              <CheckCircle checked={true} />
              <span className="ml-2">{t('Global.True')}</span>
            </div>
          ),
          value: 'true',
        },
        {
          searchString: t('Global.False'),
          label: (
            <div className="flex flex-row justify-center items-center">
              <CheckCircle checked={false} />
              <span className="ml-2">{t('Global.False')}</span>
            </div>
          ),
          value: 'false',
        },
      ];

    inputComponent = (
      <Dropdown
        onChange={(e) => handleFilterDebounced(id, e)}
        options={dropdownOptions?.map((el) =>
          typeof el === 'string' ? { value: el, label: el } : el,
        )}
        value={value}
        nullable={true}
        additionalContainerClasses={'h-10 min-h-10'}
        additionalClasses={'relative w-full flex flex-col'}
        additionalDropdownClasses={`w-auto min-w-full top-12 ${
          dropdownLeftPosition ? 'left-0' : 'right-0'
        }`}
        disabled={disabled}
        multiple={multiple}
        maxVisibleValues={1}
        maxVisibleValuesText="..."
        {...getTestProps(testId, `${id}-select`, 'testId')}
      />
    );
  } else if (filterInputType === 'datasource') {
    inputComponent = (
      <HeaderFilterDatasource
        id={id}
        handleFilters={handleFilterDebounced}
        value={value}
        dropdownLeftPosition={dropdownLeftPosition}
        validation={validation}
        disabled={disabled}
        filterDatasourceFetch={filterDatasourceFetch}
        extraOptions={extraOptions}
        {...getTestProps(testId, `${id}-datasource`, 'testId')}
      />
    );
  }
  // Case:  add text type input as default only when filterInputType was defined.
  // For the 'action' or 'selection' column, there is no need to add a default input filter
  else if (filterInputType) {
    inputComponent = (
      <Input
        onChange={(e) => handleFilterDebounced(id, e)}
        additionalInputClasses={'h-10'}
        value={currentValue}
        disabled={disabled}
        type="search"
        isSearchIcon={false}
        {...getTestProps(testId, `${id}-text`, 'testId')}
      />
    );
  }

  return (
    <div
      {...getTestProps(testId, id)}
      className={twMerge(
        'header-filter',
        'flex order-2 absolute bottom-0 left-0',
        'cursor-default p-[10px] w-full border-t border-gray dark:border-slate-800',
        'h-16 font-normal bg-gray-50 dark:bg-slate-950',
      )}
      onClick={(e) => e.stopPropagation()} // Prevent triggered sort on header
    >
      {inputComponent}
    </div>
  );
};

export default HeaderFilter;

HeaderFilter.propTypes = {
  /**
   * HeaderFilter id
   */
  id: PropTypes.string,
  /**
   * HeaderFilter input type
   */
  filterInputType: PropTypes.string,
  /**
   * HeaderFilter handle filter function
   */
  handleFilters: PropTypes.func,
  /**
   * HeaderFilter value
   */
  value: PropTypes.any,
  /**
   * HeaderFilter options
   */
  options: PropTypes.any,
  /**
   * HeaderFilter grid testid
   */
  testId: PropTypes.string,
  /**
   * HeaderFilter dropdown open left position
   */
  dropdownLeftPosition: PropTypes.bool,
  /**
   * HeaderFilter validation
   */
  validation: PropTypes.shape({
    relationContenttype: PropTypes.string,
    relationMultiple: PropTypes.bool,
  }),
  /**
   * HeaderFilter set to true to disable filter
   */
  disabled: PropTypes.bool,
  /**
   * HeaderFilter callback to get filtered data from
   */
  filterDatasourceFetch: PropTypes.func,
  /**
   * HeaderFilter dropdown multiple
   */
  multiple: PropTypes.bool,
};

HeaderFilter.defaultProps = {
  id: '',
  filterInputType: undefined,
  handleFilters: undefined,
  value: '',
  options: [],
  testId: '',
  dropdownLeftPosition: false,
  validation: {},
  disabled: false,
  multiple: false,
};
