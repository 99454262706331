import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Input from '../../components/Input/Input';
import Switch from '../../components/Switch/Switch';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import Heading from '../../components/Heading/Heading';
import ValidationToastHandler from '../../components/ValidationToastHandler/ValidationToastHandler';
import { getTestProps } from '../../lib/helpers';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

const getInitialValues = (plan) => {
  return {
    name: plan?.name || '',
    visibleName: plan?.visibleName || '',
    displayOrder: plan?.displayOrder || 0,
    stripePriceApiIdMonthly: plan?.stripePriceApiIdMonthly || '',
    stripePriceApiIdYearly: plan?.stripePriceApiIdYearly || '',
    support: plan?.support || '',
    price: plan?.price || 0,
    priceYearly: plan?.priceYearly || 0,
    fileQuota: plan?.fileQuota || 0,
    maxAssetSize: plan?.maxAssetSize || 0,
    ctdLimit: plan?.ctdLimit || 0,
    ctoLimit: plan?.ctoLimit || 0,
    scopedKeysLimit: plan?.scopedKeysLimit || 0,
    teamMembersLimit: plan?.teamMembersLimit || 0,
    webhooksLimit: plan?.webhooksLimit || 0,
    apiCallsLimit: plan?.apiCallsLimit || 0,
    bandwidthLimit: plan?.bandwidthLimit || 0,
    enabled: plan?.enabled || false,
    visible: plan?.visible || false,
    defaultPlan: plan?.defaultPlan || false,
    defaultFreePlan: plan?.defaultFreePlan || false,
    scopedKeysDocs: plan?.scopedKeysDocs || false,
    userRolesEnabled: plan?.userRolesEnabled || false,
    hostedWebhooksLimit: plan?.hostedWebhooksLimit || 0,
    officialPluginsLimit: plan?.officialPluginsLimit || 0,
    customPluginsLimit: plan?.customPluginsLimit || 0,
    allowedHostsForCustomPlugins:
      plan?.allowedHostsForCustomPlugins || 'localhost',
    apiKeyManagement: plan?.apiKeyManagement || false,
    iso27001: plan?.iso27001 || false,
    uptime: plan?.uptime || '',
    techSupport: plan?.techSupport || '',
    hosting: plan?.hosting || '',
    backupFrequency: plan?.backupFrequency || '',
    historyRetention: plan?.historyRetention || '',
    versioning: plan?.versioning || false,
    creditCard: plan?.creditCard || '',
    monthlyAnnual: plan?.monthlyAnnual || false,
    invoice: plan?.invoice || false,
    usageDashboard: plan?.usageDashboard || false,
    predefinedUserRoles: plan?.predefinedUserRoles || false,
  };
};

yup.addMethod(yup.number, 'planProperty', function (message) {
  return this.test('planProperty', message, function (value) {
    return value >= -1 && value !== Infinity;
  });
});

const PlanFormForm = ({ plan, onSubmit, disabled, navigateOnSave, testId }) => {
  const { t } = useTranslation();
  const { navigateGrid } = useGridNavigate('plans', '/plans');

  const validationSchema = yup.object({
    name: yup.string().required(t('Form.FormErrorNotBlank')),
    visibleName: yup.string(),
    displayOrder: yup.number(),
    stripePriceApiIdMonthly: yup.string(),
    stripePriceApiIdYearly: yup.string(),
    price: yup.number().min(-1),
    priceYearly: yup.number().min(-1),
    fileQuota: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    maxAssetSize: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    ctdLimit: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    ctoLimit: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    scopedKeysLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    teamMembersLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    webhooksLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    hostedWebhooksLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    apiCallsLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    bandwidthLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    officialPluginsLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    customPluginsLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    apiKeyManagement: yup.bool(),
    iso27001: yup.bool(),
    uptime: yup.string(),
    techSupport: yup.string(),
    hosting: yup.string(),
    backupFrequency: yup.string(),
    historyRetention: yup.string(),
    versioning: yup.bool(),
    creditCard: yup.string(),
    monthlyAnnual: yup.bool(),
    invoice: yup.bool(),
    usageDashboard: yup.bool(),
    predefinedUserRoles: yup.bool(),
  });

  const handleSubmit = useCallback(
    async (values, formik) => {
      const [[newValues, errors], hasErrors] = await onSubmit(values);
      await formik.setStatus({ ...formik.status, errors });

      if (!hasErrors) {
        if (navigateOnSave.current === true) {
          navigateGrid();
        } else {
          formik.resetForm({
            values: newValues,
          });
        }
      }
    },
    [navigateGrid, navigateOnSave, onSubmit],
  );

  return (
    <Formik
      initialValues={getInitialValues(plan)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <form
            id="plan-form"
            className="space-y-2 md:space-y-4 p-5 md:py-10 md:px-12"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="flex flex-col items-left gap-3 w-full">
              <Input
                name="name"
                type="text"
                label={t('Plans.Name')}
                disabled={disabled}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.name || formik.errors.name}
                {...getTestProps(testId, 'plan-name', 'testId')}
              />

              <Input
                name="visibleName"
                type="text"
                label={t('Plans.VisibleName')}
                disabled={disabled}
                value={formik.values.visibleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.visibleName ||
                  formik.errors.visibleName
                }
              />

              <Switch
                name={'enabled'}
                label={t('Plans.Enabled')}
                helpText={t('Plans.HelpText.Enabled')}
                disabled={disabled}
                checked={formik.values.enabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.enabled || formik.errors.enabled}
              />

              <Switch
                name={'visible'}
                label={t('Plans.Visible')}
                disabled={disabled}
                helpText={t('Plans.HelpText.Visible')}
                checked={formik.values.visible}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.visible || formik.errors.visible}
              />

              <Switch
                name={'defaultPlan'}
                label={t('Plans.DefaultPlan')}
                disabled={disabled}
                checked={formik.values.defaultPlan}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.defaultPlan ||
                  formik.errors.defaultPlan
                }
              />

              <Switch
                name={'defaultFreePlan'}
                label={t('Plans.DefaultFreePlan')}
                disabled={disabled}
                checked={formik.values.defaultFreePlan}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.defaultFreePlan ||
                  formik.errors.defaultFreePlan
                }
              />

              <Input
                name="displayOrder"
                type="number"
                label={t('Plans.DisplayOrder')}
                helpText={t('Plans.HelpText.DisplayOrder')}
                disabled={disabled}
                value={formik.values.displayOrder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.displayOrder ||
                  formik.errors.displayOrder
                }
              />

              <Heading
                level={3}
                children={t('Plans.PaymentDetails')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="stripePriceApiIdMonthly"
                type="text"
                label={t('Plans.StripePriceApiIdMonthly')}
                disabled={disabled}
                value={formik.values.stripePriceApiIdMonthly}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.stripePriceApiIdMonthly ||
                  formik.errors.stripePriceApiIdMonthly
                }
              />

              <Input
                name="stripePriceApiIdYearly"
                type="text"
                label={t('Plans.stripePriceApiIdYearly')}
                disabled={disabled}
                value={formik.values.stripePriceApiIdYearly}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.stripePriceApiIdYearly ||
                  formik.errors.stripePriceApiIdYearly
                }
              />

              <Input
                name="price"
                type="number"
                label={t('Plans.Price')}
                disabled={disabled}
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.price || formik.errors.price}
                {...getTestProps(testId, 'plan-price', 'testId')}
              />

              <Input
                name="priceYearly"
                type="number"
                label={t('Plans.PriceYearly')}
                disabled={disabled}
                value={formik.values.priceYearly}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.priceYearly ||
                  formik.errors.priceYearly
                }
                {...getTestProps(testId, 'plan-price-yearly', 'testId')}
              />

              <Heading
                level={3}
                children={t('Plans.Heading.General')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="fileQuota"
                type="number"
                label={t('Plans.Form.FileQuota')}
                disabled={disabled}
                value={formik.values.fileQuota}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.fileQuota || formik.errors.fileQuota
                }
                {...getTestProps(testId, 'plan-file-quota', 'testId')}
              />

              <Input
                name="ctdLimit"
                type="number"
                label={t('Plans.Form.ObjectTypesLimit')}
                disabled={disabled}
                value={formik.values.ctdLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.ctdLimit || formik.errors.ctdLimit
                }
                {...getTestProps(testId, 'plan-ctd-limit', 'testId')}
              />

              <Input
                name="ctoLimit"
                type="number"
                label={t('Plans.Form.ObjectsLimit')}
                disabled={disabled}
                value={formik.values.ctoLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.ctoLimit || formik.errors.ctoLimit
                }
                {...getTestProps(testId, 'plan-cto-limit', 'testId')}
              />

              <Input
                name="maxAssetSize"
                type="number"
                label={t('Plans.Form.MaxAssetSize')}
                disabled={disabled}
                value={formik.values.maxAssetSize}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.maxAssetSize ||
                  formik.errors.maxAssetSize
                }
                {...getTestProps(testId, 'plan-max-asset-size', 'testId')}
              />

              <Input
                name="bandwidthLimit"
                type="number"
                label={t('Plans.Form.BandwidthLimit')}
                disabled={disabled}
                value={formik.values.bandwidthLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.bandwidthLimit ||
                  formik.errors.bandwidthLimit
                }
                {...getTestProps(testId, 'plan-bandwidth-limit', 'testId')}
              />

              <Heading
                level={3}
                children={t('Plans.Heading.Webhooks')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="webhooksLimit"
                type="number"
                label={t('Plans.Form.WebhookLimit')}
                disabled={disabled}
                value={formik.values.webhooksLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.webhooksLimit ||
                  formik.errors.webhooksLimit
                }
                {...getTestProps(testId, 'plan-webhooks-limit', 'testId')}
              />

              <Input
                name="hostedWebhooksLimit"
                type="number"
                label={t('Plans.Form.HostedWebhooksLimit')}
                disabled={disabled}
                value={formik.values.hostedWebhooksLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.hostedWebhooksLimit ||
                  formik.errors.hostedWebhooksLimit
                }
                {...getTestProps(
                  testId,
                  'plan-hosted-webhooks-limit',
                  'testId',
                )}
              />

              <Heading
                level={3}
                children={t('Plans.Heading.Plugins')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="officialPluginsLimit"
                type="number"
                label={t('Plans.Form.OfficialPluginsLimit')}
                disabled={disabled}
                value={formik.values.officialPluginsLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.officialPluginsLimit ||
                  formik.errors.officialPluginsLimit
                }
                {...getTestProps(
                  testId,
                  'plan-official-plugins-limit',
                  'testId',
                )}
              />

              <Input
                name="customPluginsLimit"
                type="number"
                label={t('Plans.Form.CustomPluginsLimit')}
                disabled={disabled}
                value={formik.values.customPluginsLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.customPluginsLimit ||
                  formik.errors.customPluginsLimit
                }
                {...getTestProps(testId, 'plan-custom-plugins-limit', 'testId')}
              />

              <Input
                name="allowedHostsForCustomPlugins"
                type="text"
                label={t('Plans.Form.AllowedHostsForCustomPlugins')}
                disabled={disabled}
                value={formik.values.allowedHostsForCustomPlugins}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.allowedHostsForCustomPlugins ||
                  formik.errors.allowedHostsForCustomPlugins
                }
                {...getTestProps(testId, 'plan-allowed-hosts-limit', 'testId')}
              />

              <Heading
                level={3}
                children={t('Plans.Heading.APIAccess')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="apiCallsLimit"
                type="number"
                label={t('Plans.Form.ApiCallsLimit')}
                disabled={disabled}
                value={formik.values.apiCallsLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.apiCallsLimit ||
                  formik.errors.apiCallsLimit
                }
                {...getTestProps(testId, 'plan-api-calls-limit', 'testId')}
              />

              <Heading
                level={3}
                children={t('Plans.Heading.SecuritySLA')}
                additionalClasses="dark:text-white"
              />

              <Switch
                name="apiKeyManagement"
                label={t('Plans.Form.ApiKeyManagement')}
                checked={formik.values.apiKeyManagement}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.apiKeyManagement ||
                  formik.errors.apiKeyManagement
                }
              />

              <Input
                name="scopedKeysLimit"
                type="number"
                label={t('Plans.Form.ScopedKeysLimit')}
                disabled={disabled}
                value={formik.values.scopedKeysLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.scopedKeysLimit ||
                  formik.errors.scopedKeysLimit
                }
                {...getTestProps(testId, 'plan-scoped-keys-limit', 'testId')}
              />

              <Switch
                name={'scopedKeysDocs'}
                label={t('Plans.Form.ScopedKeysDocs')}
                checked={formik.values.scopedKeysDocs}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.scopedKeysDocs ||
                  formik.errors.scopedKeysDocs
                }
              />

              <Switch
                name="iso27001"
                label={t('Plans.Form.Iso27001')}
                checked={formik.values.iso27001}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.iso27001 || formik.errors.iso27001
                }
              />

              <Input
                name="uptime"
                type="text"
                label={t('Plans.Form.Uptime')}
                disabled={disabled}
                value={formik.values.uptime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.uptime || formik.errors.uptime}
              />

              <Input
                name="support"
                type="text"
                label={t('Plans.Support')}
                disabled={disabled}
                value={formik.values.support}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.support || formik.errors.support}
              />

              <Input
                name="techSupport"
                type="text"
                label={t('Plans.Form.TechSupport')}
                disabled={disabled}
                value={formik.values.techSupport}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.techSupport ||
                  formik.errors.techSupport
                }
              />

              <Heading
                level={3}
                children={t('Plans.Heading.Teamwork')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="teamMembersLimit"
                type="number"
                label={t('Plans.Form.TeamMembersLimit')}
                disabled={disabled}
                value={formik.values.teamMembersLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.teamMembersLimit ||
                  formik.errors.teamMembersLimit
                }
                {...getTestProps(testId, 'plan-team-members-limit', 'testId')}
              />

              <Switch
                name={'userRolesEnabled'}
                label={t('Plans.Form.UserRolesEnabled')}
                checked={formik.values.userRolesEnabled}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.userRolesEnabled ||
                  formik.errors.userRolesEnabled
                }
              />

              <Switch
                name="predefinedUserRoles"
                label={t('Plans.Form.PredefinedUserRoles')}
                checked={formik.values.predefinedUserRoles}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.predefinedUserRoles ||
                  formik.errors.predefinedUserRoles
                }
              />

              <Heading
                level={3}
                children={t('Plans.Heading.Hosting')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="hosting"
                type="text"
                label={t('Plans.Form.Hosting')}
                disabled={disabled}
                value={formik.values.hosting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.hosting || formik.errors.hosting}
              />

              <Heading
                level={3}
                children={t('Plans.Heading.Backups')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="backupFrequency"
                type="text"
                label={t('Plans.Form.BackupFrequency')}
                disabled={disabled}
                value={formik.values.backupFrequency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.backupFrequency ||
                  formik.errors.backupFrequency
                }
              />

              <Input
                name="historyRetention"
                type="text"
                label={t('Plans.Form.HistoryRetention')}
                disabled={disabled}
                value={formik.values.historyRetention}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.historyRetention ||
                  formik.errors.historyRetention
                }
              />

              <Switch
                name="versioning"
                label={t('Plans.Form.Versioning')}
                checked={formik.values.versioning}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.versioning || formik.errors.versioning
                }
              />

              <Heading
                level={3}
                children={t('Plans.Heading.Billing')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="creditCard"
                type="text"
                label={t('Plans.Form.CreditCard')}
                disabled={disabled}
                value={formik.values.creditCard}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.creditCard || formik.errors.creditCard
                }
              />

              <Switch
                name="monthlyAnnual"
                label={t('Plans.Form.MonthlyAnnual')}
                checked={formik.values.monthlyAnnual}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.monthlyAnnual ||
                  formik.errors.monthlyAnnual
                }
              />

              <Switch
                name="invoice"
                label={t('Plans.Form.Invoice')}
                checked={formik.values.invoice}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.invoice || formik.errors.invoice}
              />

              <Switch
                name="usageDashboard"
                label={t('Plans.Form.UsageDashboard')}
                checked={formik.values.usageDashboard}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.usageDashboard ||
                  formik.errors.usageDashboard
                }
              />
            </div>
            <DirtyHandler />
            <ValidationToastHandler />
          </form>
        );
      }}
    </Formik>
  );
};

export default PlanFormForm;

PlanFormForm.propTypes = {
  /**
   * On sumbit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Plan to edit
   */
  plan: PropTypes.object,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If navigates after submit represented by react ref
   */
  navigateOnSave: PropTypes.object,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

PlanFormForm.defaultProps = {
  plan: {},
  disabled: false,
  navigateOnSave: {},
  testId: '',
};
