import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSessionStorageState from 'use-session-storage-state';

const removeTypeFromFilters = (filters) => {
  Object.keys(filters || {}).forEach((key) => {
    delete filters[key].type;
  });
  return filters;
};

/**
 * Handler for grid filters. If a page ID is provided, the search parameters will be saved to the local storage
 * when user leave the page.
 *
 * @param {string} pageId Unique page ID
 * @param {boolean} includeTypeInUrl If URL search params should include filter type
 * @param {number} defaultLimit Default results limit
 * @returns {{
 *  filters: object,
 *  filtersApplied: boolean,
 *  handleFiltersChange: function,
 *  page: number,
 *  handlePageChange: function,
 *  limit: number,
 *  handleLimitChange: function
 *  removeAllFilters: function
 * }}
 */
export const useGridFilters = (
  pageId = '',
  includeTypeInUrl = false,
  defaultLimit = 20,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, setGridFilters] = useSessionStorageState(`cms.grid-filters`, {
    defaultValue: {},
  });

  useEffect(() => {
    if (!pageId) return;
    const searchParamsString = searchParams.toString();

    setGridFilters((prevState) => {
      const newState = prevState ? { ...prevState } : {};
      if (searchParamsString) newState[pageId] = searchParamsString;
      else delete newState[pageId];
      return newState;
    });
  }, [pageId, searchParams, setGridFilters]);

  const handleFiltersChange = useCallback(
    (currentFilters, parseFilters = null) => {
      setSearchParams((params) => {
        if (currentFilters && Object.keys(currentFilters).length) {
          const newFilters = parseFilters
            ? parseFilters(currentFilters)
            : currentFilters;
          const urlFilters = includeTypeInUrl
            ? newFilters
            : removeTypeFromFilters(newFilters);

          params.set('filters', JSON.stringify(urlFilters));
        } else {
          params.delete('filters');
        }
        params.delete('page');
        return params;
      });
    },
    [includeTypeInUrl, setSearchParams],
  );

  const filters = useMemo(
    () => JSON.parse(searchParams.get('filters')) || {},
    [searchParams],
  );

  const filtersApplied = useMemo(
    () =>
      filters
        ? Object.keys(filters).filter((key) => filters[key])?.length > 0
        : false,
    [filters],
  );

  const removeAllFilters = useCallback(() => {
    setSearchParams((params) => {
      params.delete('filters');
      params.delete('page');
      params.delete('limit');
      return params;
    });
  }, [setSearchParams]);

  const page = useMemo(() => +(searchParams.get('page') || 1), [searchParams]);

  const handlePageChange = useCallback(
    (newPage) => {
      setSearchParams((params) => {
        if (newPage > 1) params.set('page', newPage);
        else params.delete('page');
        return params;
      });
    },
    [setSearchParams],
  );

  const limit = useMemo(
    () => +(searchParams.get('limit') || defaultLimit),
    [defaultLimit, searchParams],
  );

  const handleLimitChange = useCallback(
    (newLimit) => {
      setSearchParams((params) => {
        if (newLimit !== defaultLimit) params.set('limit', newLimit);
        else params.delete('limit');
        params.delete('page');
        return params;
      });
    },
    [defaultLimit, setSearchParams],
  );

  return {
    filters,
    filtersApplied,
    handleFiltersChange,
    page,
    handlePageChange,
    limit,
    handleLimitChange,
    removeAllFilters,
  };
};

/**
 * Handler for navigating to page with grid.
 * It checks for page grid filters saved in local storage.
 * Returns link to grid with filters or function to navigate with filters applied.
 *
 * @param {string} pageToNavigateId Unique page ID
 * @param {string} defaultURL Base URL to navigate without filters
 * @returns {{
 *  gridLink: string,
 *  navigateGrid: function,
 * }}
 */
export const useGridNavigate = (pageToNavigateId, defaultURL) => {
  const navigate = useNavigate();
  const [gridFilters] = useSessionStorageState(`cms.grid-filters`);

  const gridLink = useMemo(() => {
    const searchParams = gridFilters?.[pageToNavigateId];
    if (!searchParams) return defaultURL;

    if (defaultURL.includes('?')) {
      return `${defaultURL}&${searchParams}`;
    } else {
      return `${defaultURL}?${searchParams}`;
    }
  }, [defaultURL, gridFilters, pageToNavigateId]);

  const navigateGrid = useCallback(() => {
    navigate(gridLink);
  }, [gridLink, navigate]);

  return { gridLink, navigateGrid };
};
